.ProgressFloat {
    height: 30px;
    padding: 5px 30px;
    position: relative;
    background: var(--Colores-Segmento-Equipo-Popular-Naranja-Naranja-8, #F6C55B);

    .barProgress {
        position: absolute;
        bottom: -35px;
        left: 16px;
        z-index: 100;

        @media (max-width: 992px) {
            svg {
                text {
                    font-size: 26px !important;
                }
            }
        }
        @media (max-width: 390px) {
            svg {
                text {
                    font-size: 24px !important;
                }
            }
        }
    }
    .mc-app-centry {
        padding-left: 70px;
    }

    button.btnProgressFloat {
        z-index: 100;
        position: absolute;
        right: 0px;
        top: 50%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        padding: 0;
        margin: 0;
        border: none;
        outline: none;
        background: none;
        font-weight: bold;
        cursor: pointer;

        img {
            display: none;
        }
    }

    @media (max-width: 992px) {
        position: fixed;
        bottom: 0;
        width: -webkit-fill-available;
        height: 70px;
        z-index: 800;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        gap: 10px;
        padding: 18px;
        padding-bottom: 129px;
        border-radius: 40px;
        -webkit-border-radius: 40px;
        -moz-border-radius: 40px;
        -ms-border-radius: 40px;
        -o-border-radius: 40px;

        .barProgress {
            position: static;
        }

        button.btnProgressFloat {
            left: 0;
            right: 0;
            margin: auto;
            width: 56px;
            top: -40px;
            padding: 20px;
            background-color: #F6C55B;
            display: inline-block;
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
            z-index: -1;
            font-size: 0px;
            transform: translateX(-50%);
            -webkit-transform: translateX(-50%);
            -moz-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            -o-transform: translateX(-50%);

            img {
                display: inline-block;
                position: relative;
                top: -14px;
            }
        }

        p {
            padding: 0;
            margin: 0;
        }
        & > .mc-app-container {
            padding: 0 0px;

            .mc-app-rely {
                position: absolute;
                left: 96px;
                right: 0;
                top: 0;
                z-index: 1;
                width: auto;

                .mc-app-centry {
                    padding-left: 0px;
                    font-size: 13px;

                    span {
                        font-weight: bold;
                        display: block;
                        font-size: 18px;
                        color: #4a4a4a;
                        margin-bottom: 5px;
                    }
                    a {
                        display: block;
                        margin-top: 8px;
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    .ProgressFloat_link {
        color: $colorText;
        text-decoration: none;
    }

    p {
        padding: 0 160px;

        @media (max-width: 992px) {
            padding: 0 0px;

        }
    }

    .btnProgressFloat2 {
        width: 60px;
        height: 60px;
        position: absolute;
        left: 50%;
        top: -26px;
        z-index: -1;
        padding: 20px;
        padding: 0;
        margin: 0;
        border: none;
        outline: none;
        background: none;
        font-weight: bold;
        background-color: #F6C55B;
        transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        img{
            position: relative;
            top: -10px;
        }
    }

    @media (max-width: 992px) {
        height: 36px !important;
    }
}