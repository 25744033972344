.mc-faqs-container {
  margin: 28px 0px;

  .mc-accordeon-container {
    position: relative;
    background-color: #f5f5f5;
    margin: 14px 0px;
    // -webkit-box-shadow: 0px 4px 6px 0px rgba(0,0,0,0.07);
    // -moz-box-shadow: 0px 4px 6px 0px rgba(0,0,0,0.07);
    // box-shadow: 0px 4px 6px 0px rgba(0,0,0,0.07);
    border-bottom: 1px solid var(--Colores-Segmento-Personas-Heroes-Gris-Gris-4, #E5E4E6);

    &.active {
      background-color: #E9FFE7;
  
      .mc-accordeon-toggle-icon {
        transform: rotate(-180deg) !important;
        top: 13px !important;
      }
    }
  
    .mc-accordeon-toggle {
      display: block;
      position: relative;
      cursor: pointer;
      padding: 16px 14px;
      padding-right: 58px;
    
      color: $colorText;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
      box-sizing: border-box;
  
      &:hover{
        opacity: 0.8;
      }
    }
    
    .mc-accordeon-toggle-icon {
      position: absolute;
      width: 22px;
      height: 22px;
      right: 16px;
      top: 17px;
      -webkit-transition: all .2s cubic-bezier(.77,0,.175,1);
      -moz-transition: all .2s cubic-bezier(.77,0,.175,1);
      -o-transition: all .2s cubic-bezier(.77,0,.175,1);
      transition: all .2s cubic-bezier(.77,0,.175,1);
    
      svg {
        width: 100%;
        height: auto;
      }
    }
    
    .mc-accordeon-content {
      padding-bottom: 22px;
      padding-top: 0px;
      padding-right: 45px;
      padding-left: 16px;
      text-align: justify;
      color: #616161;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }
  }

  &.mc-faqs-container-type-1 {

  }
  &.mc-faqs-container-type-2 {

  }
}