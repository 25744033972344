.SliderAlied{    
    .slider-container{
        .items{
            .content{                
                .imgBg{
                    img{
                        width: 100%;
                    }

                }
                .info{
                    display: flex;                                      
                    align-items: center;
                    background-color: #FFFFFF;
                    padding: 10px 2px;
                    border-radius: 0px 0px 16px 16px;
                    height: 92px;
                    .logoAllied{
                        padding: 5px;
                        
                        img{
                            width: 100%;
                            object-fit: cover;
                        }
                    }
                    .dataAllied{                        
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .logoAllied{
                            width: 60px;

                            img {
                                width: 100%;
                            }
                        }
                        h4{
                            color: $gray;
                            font-size: .875rem;
                        }
                        p.rule{
                            color: $green2;
                            font-family: $CoreSansA75ExtraBold;
                        }
                        p.store{
                            font-size: .875rem;
                            font-family: $CoreSansA65Bold;
                            a{
                                color: $gray;
                                text-decoration: none;
                                &:hover{
                                    text-decoration: underline;
                                
                                }
                            }
                        }
                        &.Puppis {
                            img {
                                top: 18px;
                                height: auto !important;
                            }
                        }
                    }
                }
            }
        }
    }

    .slick-prev, .slick-next {
        font-size: 0;
        line-height: 0;
        position: absolute;
        top: auto;
        bottom: -40px;
        display: block;
        width: 20px;
        height: 20px;
        padding: 0;
        transform: translate(0, -50%);
        cursor: pointer;
        color: transparent;
        border: none;
        outline: none;  
        z-index: 3;      
    }
    .slick-prev {
        left: 10px;
    }
    .slick-next {
        right: 68%;
    }
    .slick-dots {
        position: absolute;
        bottom: -30px;
        display: block;
        width: 100%;
        padding: 0;
        margin: 0;
        list-style: none;
        text-align: left;
        padding-left: 35px;

        li {
            background-color: transparent;
            border-radius: 100px;
            border: 2px solid #016836;
            width: 16px;
            height: 16px;

            button {
                width: 100%;
                height: 100%;
                display: block;
                padding: 0px;
    
                &:before {
                    display: none;
                }
            }
        }

        

        @media (max-width: 992px) {
            padding-left: 0;
            text-align: center;
         
        }
    }
    .slick-dots li {
        position: relative;
        display: inline-block;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;
    }

    .slick-dots li.slick-active {
        background-color: #016836;
        width: 48px;
    }

    &.size-4{
        .slick-next{
            left: 164px;
            @media (max-width: 992px) {
                left: 263px;

             
            }
        }
    }
    &.size-3{
        .slick-next{
            left: 143px;
            @media (max-width: 992px) {
                left: 263px;

             
            }
        }
    }
    &.size-2{
        .slick-next{
            left: 164px;
            @media (max-width: 992px) {
                left: 263px;

             
            }
        }
    }
}