.Dropdown {
    padding: 15px 10px;
    width: 184px;
    border: 1px solid var(--Colores-Segmento-Personas-Primario-Primario-Personas-2, #016836);
    background: var(--Colores-Neutros-Neutro-9, #FFF);
    border-radius: var(--Sizes-l, 24px);
    -webkit-border-radius: var(--Sizes-l, 24px);
    -moz-border-radius: var(--Sizes-l, 24px);
    -ms-border-radius: var(--Sizes-l, 24px);
    -o-border-radius: var(--Sizes-l, 24px);
    position: absolute;
    top: 55px;
    left: -40px;
    z-index: 500;
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: end;

    .item-submenu {
        width: 100%;

        &:last-child {
            border-top: 1px solid #F5BD47;
            padding-top: 10px;
            cursor: pointer;
        }

        .link-submenu {
            display: inline-block;
            color: $colorText;
            font-size: $em-12;
            font-weight: bold;
            margin-bottom: 10px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            text-decoration: none;
            gap: 10px;

            .textSubmenu {
                color: $colorText;
                margin-bottom: 5px;

                &.logout {
                    color: $red;
                }

            }
        }

    }

    .rectangle {
        position: absolute;
        z-index: -1;
        top: -29px;
        left: 50%;
        transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);

        @media (max-width: 992px) {
            display: none;

        }
    }

    &.show {
        opacity: 0;
        visibility: hidden;
    }

    @media (max-width: 992px) {
        top: -207px;
        left: -170px;
        width: 200px;

        li {
            a {
                white-space: nowrap;
            }
        }
    }

}

.logoMC {
    @media (max-width: 992px) {
        display: none;

    }
}