svg {
  background-color: #FFFFFF;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  .circle-background {
    fill: none;
    stroke: #89BD23;
    stroke-width: 2px;
  }
  .circle-progress {
    fill: none;
    // stroke:#89BD23;
    stroke-linecap: round;
    stroke-linejoin: round;
    transition: stroke-dashoffset 1s linear 0s !important;
  }
  .circle-text {
    // font-size: 27.84px;
    font-weight: bold;
  }
  &.bg-transparent {
    
  }
}
