.mc-slider-rotator {
  position: relative;
  margin-bottom: 50px;

  .slick-slider {
    .slick-slide {
      transform: scale(0.6);
      opacity: 0;
      -webkit-transition: all .3s cubic-bezier(.77,0,.175,1);
      -moz-transition: all .3s cubic-bezier(.77,0,.175,1);
      -o-transition: all .3s cubic-bezier(.77,0,.175,1);
      transition: all .3s cubic-bezier(.77,0,.175,1);

      &.slick-active {
        transform: scale(1);
        opacity: 1;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
}
    }

    .slick-dots {
      text-align: left;
      margin-left: 30px;
      @media (max-width: 1200px) {
        text-align: center;
        margin-left: 0;
       
      }
      li {
        width: 10px;
        height: 10px;
        background-color: #FFFFFF;
        border-radius: 50px;
        margin: 0 4px;
        -webkit-transition: all .3s cubic-bezier(.77,0,.175,1);
        -moz-transition: all .3s cubic-bezier(.77,0,.175,1);
        -o-transition: all .3s cubic-bezier(.77,0,.175,1);
        transition: all .3s cubic-bezier(.77,0,.175,1);
        border: solid 2px $green;

        button {
          width: 100%;
          height: 100%;

          &:before {
            display: none;
          }
        }
        &.slick-active {
          background-color: $green;
          width: 48px;
        }
      }
    }
  }
  

  .mc-slider-rotator__slide {
    position: relative;
  }
}