.mc-login {
  position: relative;
  height: auto;  
  padding: 0px 0px;
  min-height: 400px;
  
  &__form {
    position: relative;
    display: block;
    margin: 0 auto;
    background-color: #fff;
    padding: 0 20px;
    padding-bottom: 28px;
    box-sizing: border-box;  
    @media (max-width: 992px) {
      padding: 0 10px;
      padding-bottom: 25px;
      border: 1px solid var(--Colores-Neutros-Neutro-8, #DDD);
      background: var(--Colores-Neutros-Neutro-9, #FFF);
      border-radius: var(--Sizes-m, 16px);
      -webkit-border-radius: var(--Sizes-m, 16px);
      -moz-border-radius: var(--Sizes-m, 16px);
      -ms-border-radius: var(--Sizes-m, 16px);
      -o-border-radius: var(--Sizes-m, 16px);
      box-shadow: 0px 36px 67px -44px;
}  

    &--fields {
      position: relative;
      margin-bottom: 22px;
    }

    &--overlay {
      position: absolute;
      left: 0px;
      top: 0px;
      bottom: 0px;
      right: 0px;
      z-index: 5;
      background-color: #fff;
      opacity: 0.5;
      cursor: no-drop;
    }
  }
}

.mc-input-container {

  &__box {
    position: relative;
    margin-bottom: 20px;

    label {
      font-size: 16px;
      font-style: normal;      
      line-height: 22px;
      display: block;
      margin-bottom: 12px;
      text-align: left;
      color: $gray2;
    }

    input, textarea {
      width: 100%;
      box-sizing: border-box;
      background-color: #fff;
      border: 0px;
      outline: 0px;
      height: 40px;
      position: relative;      
      font-size: 16px;
      padding: 0 12px;
      min-width: 100%;
      max-width: 100%;      
      color: #9E9E9E;
      border-radius: var(--Sizes-s, 8px);
      border: 1px solid var(--Colores-Neutros-Neutro-6, #9E9E9E);
      background: var(--Colores-Neutros-Neutro-9, #FFF);

      &[type="date"] {
        text-transform: uppercase;
      }
    }

    textarea {
      padding-top: 5px;
      padding-bottom: 5px;
      resize: none;
      height: 130px;
    }

    input[type="date"]::-webkit-calendar-picker-indicator {
      background: none;
      background-size: 0px;
    }

    input::placeholder,
    input:-ms-input-placeholder,
    input::-ms-input-placeholder { 
      opacity: 1 !important;
    }

    &.terms  {
      display: block;
      position: relative;
      padding-left: 30px;
      margin-bottom: 0px !important;
      cursor: pointer;
      font-size: 16px !important;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      font-weight: normal !important;
      line-height: 8px;

      a {
        text-decoration: none;
        margin-left: 4px;
        color: $gray;
        cursor: pointer !important;
        font-size: 13px;
        position: relative;
        z-index: 3;
        &:hover{
        text-decoration: underline;
        }
      }
      input {
        position: absolute;
        cursor: pointer;
        height: 100%;
        width: 100%;
        left: 0px;
        top: 0px;
        z-index: 2;
        opacity: 0;
        color: #5F5F5F;
      }
      label {
        display: inline;
        font-size: 13px;
        color: #5F5F5F;
      
      }
      .mc-input-checkbox__checkmark {
        position: absolute;
        top: 0px;
        left: 0;
        height: 18px;
        width: 18px;
        background-color: transparent;
        border-radius: 6px;
        border: 2px solid #919191;
      }
      .mc-input-error {
        margin-top: 14px;
        line-height: 16px !important;
      }
    }

    &.terms:hover input ~ .mc-input-checkbox__checkmark {
      background-color: rgba(255,255,255,0.3);
    }
    &.terms input:checked ~ .mc-input-checkbox__checkmark {
      background-color: $primaryColor;
      border: 2px solid $primaryColor !important;
    }
    
    .mc-input-checkbox__checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }
    
    &.terms input:checked ~ .mc-input-checkbox__checkmark:after {
      display: block;
    }
    
    &.terms .mc-input-checkbox__checkmark:after {
      left: 6px;
      top: 2px;
      width: 4px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg) scale(0.8);
      -ms-transform: rotate(45deg) scale(0.8);
      transform: rotate(45deg) scale(0.8);
    }

    .mc-input-by-character {
      input {
        display: inline-block;
        vertical-align: middle;
        width: 20%;
        min-width: 20px;
        margin: 0 1%;

        &:first-child {
          margin-left: 0px;
        }
        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }
}

.mc-input-separated-container {
  input {
    min-width: 20px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin: 0 1%;
    color: #000;
  }
}

.mc-input-container__box--error {
  input {
    border: 1px solid red !important;
  }
}

.mc-input-error {
  color: red;
  background-color: rgb(255, 244, 244);
  border: 1px solid red;
  font-size: 12px;
  display: block;
  padding: 8px 14px;
  margin-top: 8px;
  border-radius: 4px;
}

.mc-input-container__box{
  &.dataUser{
    margin-bottom: 0;
  }
    input{
      height: 33px;
      &::placeholder{
        color: #7E7E7E;
        font-size: .8125rem;
      }
    }

    &.select{    
      margin-bottom: 0;  
      select{
        width: 100%;
        height: 33px;
        color: #7E7E7E;
        font-size: .8125rem;
        border-radius: var(--Sizes-s, 6.609px);
        border: 0.826px solid var(--Colores-Neutros-Neutro-5, #7E7E7E);
        background: var(--Colores-Neutros-Neutro-9, #FFF);
        option{
          background-color: red;
          width: 30px;
        }

      }
    }
}