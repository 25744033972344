.mc-alliances-container {
  margin: 30px 0px;

  &__items {

    &--item {
      position: relative;
      margin-bottom: 30px;

      &__image {
        display: inline-block;
        vertical-align: top;
        // width:45%;

        span {
          display: block;
          width: 100%;
          // background-color: #e7e7e7;
          border-radius: 6px;
          background-position: center center !important;
          background-repeat: no-repeat !important;
          background-size: 70% !important;

          img {
            width: 100%;
            height: auto;
            display: block;

            &.dummy {
              // opacity: 0 !important;
            }
          }
        }

        .dto {
          
          display: flex;
          align-items: center;
          justify-content: center;
          width: 90px;
          height: 90px;
          border-radius: var(--Sizes-s, 8px);
          background: #FFE3A8;
          box-shadow: 0px 15px 27px -16px #E5AE5F;
          h4{
            color: $green2;
            font-family: $CoreSansA65Bold;
            font-size: 30px;
          }

          .rulesDto {
            color: $green2;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            sup{
              font-size: 14px;
              font-family: $CoreSansA65Bold;
              color: $green2;
            }
            sub{
              font-size: 12px;
              font-family: $CoreSansA65Bold;
              color: $green2;
            }
          }
        }
      }

      &__info {
        display: inline-block;
        vertical-align: top;
        width: 100%;
        box-sizing: border-box;
        text-align: center;
        // padding-left: 10px;

        .mc-alliances-button {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-decoration-line: underline;
          color: $green2;
          margin: 8px 0px;
          display: block;
          border: 0px;
          background-color: transparent;
          padding: 0px;
          cursor: pointer;
        }
      }

      &__title {
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
        margin: 5px;
        color: $gray;        
        font-family: "CoreSansA65Bold";        
        text-decoration: none;

        a.link_title{
          font-size: 20px;
          font-family: $CoreSansA65Bold;
          font-weight: 700;
          line-height: 22px;
          margin-bottom: 5px;
          color: $gray;
          text-decoration: none;
          &:hover{
            text-decoration: underline;
          
          }
        }
      }

      &__desc {

        * {
          font-size: 15px;
          font-style: normal;
          font-family: $CoreSansA45Regular;
          line-height: 22px;
          color: $gray;          
        }
      }

      &__container {
        background-color: #FFFFFF;
        padding: 20px 10px;
        margin: 0 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-radius: 16px;
        -webkit-border-radius: 16px;
        -moz-border-radius: 16px;
        -ms-border-radius: 16px;
        -o-border-radius: 16px;
        box-shadow: 0px 4px 4px 0px rgba(1, 104, 54, 0.25);

        &.terms {
          .mc-custom-modal__center {
            @media only screen and (min-width: 992px) {
              width: 900px;
            }

            .mc-custom-modal__center--window {
              max-height: 80%;

              .mc-custom-modal__center--window__desc {
                text-align: left;
                max-height: 280px;
                overflow: auto;

                @media only screen and (min-width: 992px) {
                  max-height: 320px;
                }
              }
            }
          }
        }
        .dto-logo{
          display: flex;
          justify-content: center;
          align-items: center;          
          border-radius: var(--Sizes-s, 8px);
          border: 1px solid #E1F8E8;

          .img_dto{
            width: 100%;
            max-width: 120px;
            @media (max-width: 576px) {
              max-width: 100px;
             
            }
          }
          .img_logo{
            width: 100%;
            max-width: 168px;
            @media (max-width: 576px) {
              max-width: 130px;
             
            }
          }
        }
        &.alliedEDV{
          .rule-allied{
            font-size: 14px;            
            font-family: $CoreSansA45Regular;
            padding: 0 10px;
            .cupon{
              font-size: $em-16;
              font-family: $CoreSansA65Bold;
              font-weight: bold;
              color: $gray3;
            }
          }
          .mc-alliances-container__items--item__desc{
            font-size: 12px;
          }
        }
      }

      a {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration-line: underline;
        color: $colorText;
        margin: 5px 0px;
        display: block;
      }

      &.type-1 {}

      &.type-2 {
        margin-bottom: 40px;

        .mc-alliances-container__items--item__image {
          position: absolute;
          left: 0px;
          top: 0px;
          width: 80px;
          height: 80px;
        }

        .mc-alliances-container__items--item__info {
          height: 80px;
          position: relative;
          padding-left: 90px;
          width: 100%;
          margin-bottom: 12px;

          .mc-alliances-container__items--item__rule {
            background-color: $primaryColor;
            color: #fff;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 25px;
            text-align: center;
            padding: 7px 0px;
          }

          .mc-alliances-container__items--item__title {
            position: absolute;
            bottom: 0px;
            left: 90px;
            margin-bottom: 0px;
          }

        }
      }

      &.type-3 {
        background-color: rgb(233, 233, 233);
        padding: 18px;
        padding-bottom: 12px;
        box-sizing: border-box;

        .mc-alliances-container__items--item__info {
          height: 75px;
          position: relative;
          width: 100%;
          background-color: rgb(196, 196, 196);
          border-radius: 8px;
          padding: 10px;
          box-sizing: border-box;
          padding-left: 85px;
          margin-bottom: 12px;

          .mc-alliances-container__items--item__title {
            font-size: 16px;
            font-style: normal;
            font-weight: 900;
            line-height: 22px;
            margin-top: 0px;
            margin-bottom: 2px;
          }

          .mc-alliances-container__items--item__rule {
            font-size: 24px;
            font-style: normal;
            font-weight: 900;
            line-height: 28px;
          }

          .mc-alliances-container__items--item__image {
            position: absolute;
            left: 0px;
            top: 0px;
            width: 75px;
            height: 75px;
            -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.3);
            -moz-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.3);
            box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.3);
          }
        }
      }

      &.type-4 {
        .mc-alliances-container__items--item__info {
          padding-left: 50px;
          width: 100%;

          .mc-alliances-container__items--item__labels {
            background-color: rgb(243, 243, 243);
            box-sizing: border-box;
            padding: 16px;
            padding-left: 50px;

            .mc-alliances-container__items--item__title {
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: 22px;
            }

            .mc-alliances-container__items--item__rule {
              font-size: 24px;
              font-style: normal;
              font-weight: 900;
              line-height: 28px;
              margin-bottom: 6px;
            }

            .mc-alliances-container__items--item__desc {
              * {
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
              }
            }
          }

          .mc-alliances-container__items--item__image {
            position: absolute;
            left: 0px;
            top: 0px;
            bottom: 0px;
            margin: auto;
            width: 86px;
            z-index: 1;
            height: 86px;

            span {
              border-radius: 200px;
              background-color: #fff;
              background-size: 60% !important;
              -webkit-box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
              -moz-box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
              box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
            }
          }
        }
      }
    }
  }

  .mc-alliances-container__actions {
    background-color: $primaryColor;
    text-align: center;
    margin: 18px auto;
    max-width: 390px;

    a {
      color: #fff;
      text-decoration: none;
      display: block;
      padding: 10px 0;

      &:hover {
        cursor: pointer;
        opacity: 0.7;
      }
    }
  }

  &.mc-alliances-column-1 {
    .mc-alliances-container__items {

      @media only screen and (min-width: 992px) {
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
      }

      &--item {
        @media only screen and (min-width: 992px) {
          width: 100%;
          display: inline-block;
          vertical-align: top;
          margin: 0 1.5%;
          margin-bottom: 28px;
        }
      }
    }
  }

  &.mc-alliances-column-2 {
    .mc-alliances-container__items {

      @media only screen and (min-width: 992px) {
        max-width: 700px;
        margin-left: auto;
        margin-right: auto;
      }

      &--item {
        @media only screen and (min-width: 992px) {
          width: 47%;
          display: inline-block;
          vertical-align: top;

        }
      }
    }
  }

  &.mc-alliances-column-3 {
    .mc-alliances-container__items {

      @media only screen and (min-width: 992px) {
        margin-left: -1.5%;
        margin-right: -1.5%;
      }

      &--item {
        @media only screen and (min-width: 992px) {
          width: 33.3%;
          display: inline-block;
          vertical-align: top;

        }
      }
    }
  }
}

.inf_dto{
  min-height: 72px;
}
// .rule-allied{
//   min-height: 50px;
// }
.SliderAliedEDV{
  .myClassName{
    .slick-list{
      padding: 10px 0;
    }
  }

  .slick-prev, .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: auto;
    bottom: -50px;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    z-index: 3;
}
.slick-prev {
  left: 30%;
}
.slick-next {
  right: 30%;
}
.slick-dots{
  bottom: -40px;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
  &.slick-active{
    button{
      background-color: $green2;      
    }
  }
  button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    border-radius: var(--Sizes-l, 24px);
    border: var(--Sizes-xxs, 2px) solid var(--Colores-Segmento-Personas-Primario-Primario-Personas-2, #016836);
    &:before {
      content: "";
    
  }
}
}

}