.mc-counter-down {
  position: relative;
	background-color: rgb(233, 233, 233);
	border: 1px solid gray;
	box-sizing: border-box;
	padding: 20px;
	text-align: center;
  width: 100%;
  max-width: 520px;
  margin: 0 auto;

  border-radius: var(--Sizes-s, 8px);
  border: var(--Sizes-xxs, 2px) solid var(--Colores-Segmento-Equipo-Popular-Naranja-Naranja-5, #FFA300);
  background: var(--Colores-Segmento-Equipo-Popular-Naranja-Naranja-7, #F5BD47);
  box-shadow: 0px 59px 16px 0px rgba(0, 152, 68, 0.00), 0px 38px 15px 0px rgba(0, 152, 68, 0.01), 0px 21px 13px 0px rgba(0, 152, 68, 0.05), 0px 9px 9px 0px rgba(0, 152, 68, 0.09), 0px 2px 5px 0px rgba(0, 152, 68, 0.10);

  ul {
    padding: 0px;
    margin: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;

    li {
      display: inline-block;
      vertical-align: middle;
      font-size: 12px;
      font-weight: bold;

      span {
        display: block;
        font-size: 24px;
      }
      
    }
    .separate{
      font-weight: bold;
      color: black;
    }
  }
}