.mc-footer-container {
  position: relative;
  background-color: #FFFFFF !important;
  padding-bottom: 70px;
  padding-top: 95px;

  @media only screen and (min-width: 992px) {
    padding: 74px 0;
  }
  @media only screen and (max-width: 992px) {
    padding-top: 10px;
    margin-top: 10px;
  }

  &__cards {
    margin: 0 auto;
    // margin-bottom: -28px;
    width: 280px;
    max-width: 100%;
    display: block;
    position: relative;
    // top: -32px;
  }

  &__bg {
    padding-bottom: 30px;
  }

  &__section {
    border-bottom: 1px solid #BDBDBD;
    padding: 6px 0px;

    &--links {
      text-align: center;
      margin-bottom: 0px;
      position: relative;
      padding: 0px;
      margin: 20px auto;
      max-width: 380px;

      a {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration-line: underline;
        color: #202020;
        display: inline-block;
        width: 50%;
        margin: 0px;
        position: relative;

        &:nth-child(2) {
          &::before {
            content: '';
            width: 1px;
            height: 15px;
            background-color: #202020;
            position: absolute;
            left: 4px;
            top: 0px;
            bottom: 0px;
            margin: auto;
          }
        }
      }
    }

    p {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      text-align: center;
      margin: 28px 0;
      color: #202020;
    }
  }

  &__subbg {
    background-color: $primaryColorDark;
    padding: 30px 0px;
    text-align: center;

    @media only screen and (min-width: 992px) {
      padding: 20px 0px;
    }

    img {
      width: 80%;
      height: auto;
      max-width: 270px;

      @media only screen and (min-width: 992px) {
        max-width: 220px;
      }
    }
  }

  &__image {
    width: 382px;
    height: auto;
    display: block;
    margin: 24px auto;
    @media (max-width: 576px) {
    width: 100%;
     
    }
  }

  &__text {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    padding-top: 15px;
    position: relative;
    color: #202020;

    p {
      padding: 0 10px;
      text-align: center;
      &:nth-child(2){
        margin: 1.875rem 40px;
      }

      @media only screen and (min-width: 992px) {
        padding: 0 0px;
      }
    }
  }

  .mc-footer-vigilated {
    position: absolute;
    left: 5px;
    bottom: 380px;
    width: 18px;
    z-index: 1;
    height: auto;
    // -webkit-filter: invert(100%);
    // filter: invert(100%);

    @media only screen and (min-width: 992px) {
      left: 0px;
      position: absolute;
      bottom: 186px;
    }
    @media only screen and (max-width: 992px) {
      bottom: 230px;
    }
  }
  

  &.mc-footer-container-type-2 {

    .mc-footer-container__cards {
      position: relative;
      top: auto;
      margin-bottom: 0px;
    }

    .mc-footer-container__bg {
      padding: 30px 0px;
      text-align: center;

      &--image {
        width: 100%;
        height: auto;
        max-width: 400px;
        margin-bottom: 8px;

        @media only screen and (min-width: 992px) {
          position: absolute;
          left: 20px;
          max-width: 250px;
        }
      }

      .mc-footer-container__sections {
        text-align: left;

        @media only screen and (min-width: 992px) {
          display: block;
          padding-left: 380px;
          box-sizing: border-box;
        }

        &--section {
          margin-bottom: 36px;

          h4 {
            margin-bottom: 10px;
            color: #fff;
          }
          ul {
            margin: 0px;

            a {
              display: block;
              width: 100%;
              text-align: left;
              font-size: 16px;
              line-height: 22px;
              text-decoration: none;
              cursor: pointer;
              margin-bottom: 10px;

              &:hover {
                text-decoration: underline;
                opacity: 0.7;  
              }

              &::before {
                display: none !important;
              }
            }
          }

          p {
            margin-top: 0px;
            color: #fff;
          }

          @media only screen and (min-width: 992px) {
            position: relative;
            margin-bottom: 22px;

            h4 {
              position: absolute;
              top: 0px;
              margin: 0px !important;
            }
            ul, p {
              display: block;
              max-width: 100%;
              text-align: left;
              padding-left: 130px;
              a {
                display: inline-block;
                vertical-align: middle;
                width: auto;
                margin-right: 20px;
              }
            }
          }
        }
      }

      .mc-footer-container__image {
        text-align: left;
        display: block;
        margin: 20px 0px;
        margin-bottom: 6px;

        @media only screen and (min-width: 992px) {
          margin-top: -28px;
        }
      }
    }

    .mc-footer-vigilated {
      @media only screen and (min-width: 992px) {
        bottom: 0px;
      }
    }
  }

  &.mc-footer-container-type-3 {

    .mc-footer-container__bg {
      padding-top: 50px;

      @media only screen and (min-width: 992px) {
        position: relative;
      }
    }

    .mc-footer-container__cards {
      width: 200px;
      max-width: 350px;
      top: 0px;
      margin-bottom: 28px;
    }

    .mc-footer-container__bg--image {
      display: block;
      margin: 20px auto;
      width: 250px;

      @media only screen and (min-width: 992px) {
        margin-top: 0px;
        position: relative;
        left: 8%;
      }
    }

    .mc-footer-container__image {
      margin-bottom: 0px;

      @media only screen and (min-width: 992px) {
        position: absolute;
        right: 20px;
        top: 0px;
        margin-top: 0px;
        width: 160px;
      }
    }

    .mc-footer-container__section--links {
      margin: 28px auto;
      margin-top: 0px;

      @media only screen and (min-width: 992px) {
        a:nth-child(2)::before {
          left: -18px;
        }
      }

    }

    .mc-footer-container__section {
      border: 0px !important;

      @media only screen and (min-width: 992px) {
        position: absolute;
        left: 0px;
        right: 0px;
        top: 0px;
        padding-top: 0px;

        .mc-footer-container__section--links {
          text-align: left;
          width: 50%;
          position: absolute;
          left: 20px;
          top: 0px;
        }
      }
    }

    .mc-footer-container__subbg {
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      background-color: transparent !important;
      border-top: 1px solid rgba(255,255,255,0.3);
      margin-top: 40px;
      color: #fff;
      
    }

    .mc-footer-container__text  {
      @media only screen and (min-width: 992px) {
        padding-top: 35px;
      }
    }

    .mc-footer-container__cards {
      @media only screen and (min-width: 992px) {
        display: none !important;
      }
    }
  }
  .items-contacts{
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 992px) {
     flex-direction: column;
    }
    gap: 20px;
    li{
      a{
        color: $green;
        font-weight: bold;
      }
    }
  }
}