.mechanic {
	.card-mecanica {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 20px;

		@media (max-width: 1300px) {
			gap: 10px;
		}

		.card-mechanic {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			padding-bottom: 40px;
			background-color: #FFFFFF;
			border-radius: 24px;
			-webkit-border-radius: 24px;
			-moz-border-radius: 24px;
			-ms-border-radius: 24px;
			-o-border-radius: 24px;

			.imgMechanic {
				text-align: center;

				img {
					object-fit: cover;
					width: 100%;
					max-width: 100%;
				}
			}

			&:hover {
				border: solid 1px $gray;
			}
		}

	}

	.mechanic-Slider {
		.slick-dots {
			position: absolute;
			bottom: -40px;
			display: block;
			width: 100%;
			padding: 0;
			margin: 0;
			list-style: none;
			text-align: center;
		}

		.slick-dots li {
			position: relative;
			display: inline-block;
			width: 20px;
			height: 20px;
			margin: 0 5px;
			padding: 0;
			cursor: pointer;
		}

		.slick-dots li button:before {
			display: none;
		}

		.slick-dots li button {
			font-size: 0;
			line-height: 0;
			display: block;
			width: 20px;
			height: 20px;
			padding: 5px;
			cursor: pointer;
			color: transparent;
			border: 0;
			outline: none;
			background: transparent;
			border-radius: var(--Sizes-l, 24px);
			border: var(--Sizes-xxs, 2px) solid var(--Colores-Segmento-Personas-Primario-Primario-Personas-1, #009844);
		}

		li.slick-active {
			border-radius: var(--Sizes-l, 24px);
			background: var(--Colores-Segmento-Personas-Primario-Primario-Personas-1, #009844);
			width: 48px;
		}
	}

}