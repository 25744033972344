.mc-custom-modal {
  position: fixed;
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  z-index: 999;
  visibility: hidden;
  opacity: 0;

  &__overlay {
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: 0px;
    z-index: 1;
    background-color: rgba(1, 104, 54, 0.70);
  }

  &__center {
    width: 310px;
    height: 100%;
    margin: 0 auto;
    max-width: 90%;
    z-index: 1;

    &--window {
      position: relative;
      background: #fff;
      visibility: hidden;
      opacity: 0;
      transform: scale(0);
      border-radius: 28px;
      box-sizing: border-box;
      padding: 24px;
      box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.1);
      -webkit-box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.1);
      -webkit-border-radius: 28px;
      -moz-border-radius: 28px;
      -ms-border-radius: 28px;
      -o-border-radius: 28px;

      &__image {
        height: 80px;
        width: 90px;
        margin: 0 auto;
        display: block;
        margin-top: 20px;
        box-sizing: border-box;
        background-position: center center !important;
        background-repeat: no-repeat !important;
        background-size: 80% !important;
      }

      &__title {
        text-align: center;
        margin: 0px 0px;
        font-size: 1.3125rem;
        font-style: normal;
        font-weight: 700;
        line-height: 36px;
        color: $gray;
      }

      &__desc {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        text-align: center;
        margin: 10px 0;
      }

      &__actions {
        margin: 20px 0px;

        @media only screen and (min-width: 992px) {
          margin-top: 10px;
        }

        .mc-button-container {
          margin: 10px 0px;
        }
      }

      &__terms {
        text-align: center;
        margin-bottom: 16px;

        a {
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 14px;
          text-decoration-line: underline;
          color: $colorText;
        }
      }

      &.step-2 {
        width: 100%;
        max-width: 374px;
        p{
          line-height: normal;
        }
        border-radius: 14.978px;
        .bg-modal-step-2{
          border-radius: var(--Size-lg, 14.625px);
          background: var(--Colores-Segmento-Personas-Heroes-Gris-Gris-5, #F7F8F9);
        }
        .mc-custom-modal__center--window__image {
          padding-top: 0;
          margin-top: 0;
        }

        .mc-custom-modal__center--window__desc {
          margin: 0;
        }
        .btnModal-2{
          display: flex;
          gap: 10px;
          flex-direction: row-reverse;
          justify-content: center;
        }
      }
      &.step-1{
        border-radius: 14.978px;
        .bg-modal-step-1{
          border-radius: var(--Size-lg, 14.625px);
          background: var(--Colores-Segmento-Personas-Heroes-Gris-Gris-5, #F7F8F9);
        }
      }
      &.step-3{
        padding: 10px 0;
        width: 100%;
        max-width: 372px;
        border-radius: 14.978px;
        .bg-modal-step-3{
          margin-top: 40px;
          border-radius: var(--Sizes-m, 16px);
          background: var(--Colores-Segmento-Personas-Heroes-Gris-Gris-5, #F7F8F9);
          padding: 10px;
        }
        .mc-custom-modal__close{
          svg{
            width: 15px;

          }
        }
      }
    
    }

    &.step-3{
      width: 372px;
    }
  }

  &__close {
    position: absolute;
    right: 22px;
    top: 20px;
    cursor: pointer;

    &:hover {
      opacity: 0.6;
    }
  }

  &__opened {
    visibility: visible;
    opacity: 1;
    transform: scale(1);

    .mc-custom-modal__center--window {
      visibility: visible;
      opacity: 1;
      transform: scale(1);
    }
  }

  .bigDesc {
    height: 117px;
    overflow-y: scroll;
    p{
      line-height: normal !important;
      padding: 0 5px;
      &:nth-child(1){
        line-height: 22px !important;
        margin-bottom: 10px;
      }
    }
  }
}