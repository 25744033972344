@use "sass:math";
// None
$primaryColor: #353535;
$primaryColorHover: #5e5e5e;
$primaryColorLight: #6d6d6d;
$primaryColorDark: #131313;
$primaryColorDisable: #818181;

$secondaryColor: #7a89a8;
$secondaryColorHover: #95a2c0;
$secondaryColorLight: #7791c9;
$secondaryColorDark: #3a3e47;
$secondaryColorDisable: #818181;

$errorColor: #ee0000;
$errorColorLight: #fff1f1;

// Avvillas
/*
$primaryColor: #e1001d;
$primaryColorHover: #f83a54;
$primaryColorLight: #ff98a6;
$primaryColorDark: #a70016;
$primaryColorDisable: #818181;

$secondaryColor: #0048db;
$secondaryColorHover: #155eee;
$secondaryColorLight: #80aaff;
$secondaryColorDark: #002d86;
$secondaryColorDisable: #818181;
*/


// Bogotá
/*
$primaryColor: #0043a9;
$primaryColorHover: #105ed4;
$primaryColorLight: #76adff;
$primaryColorDark: #002864;
$primaryColorDisable: #818181;

$secondaryColor: #edbc2c;
$secondaryColorHover: #fad360;
$secondaryColorLight: #fff6da;
$secondaryColorDark: #a37a00;
$secondaryColorDisable: #818181;
*/
$colorText: #202020;

// Banco popular
$green:#009844;
$green2:#016836;
$gray:#5F5F5F;
$gray2:#7E7E7E;
$gray3:#9E9E9E;
$red:#C30600;
$yellow:#F5BD47;

// Función que convierte PX en EM
$browser-context: 16; // Default
@function em($pixels, $context: $browser-context) {
  @return #{$pixels/$context}em;
}

// Fonts
$CoreSansA45Regular: 'CoreSansA45Regular';
$CoreSansA65Bold: 'CoreSansA65Bold';
$CoreSansA75ExtraBold: 'CoreSansA75ExtraBold';

// font sizes
$em-5: em(5);
$em-8: em(8);
$em-10: em(10);
$em-11: em(11);
$em-12: em(12);
$em-13: em(13);
$em-14: em(14);
$em-15: em(15);
$em-16: em(16);
$em-17: em(17);
$em-18: em(18);
$em-20: em(20);
$em-21: em(21);
$em-22: em(22);
$em-24: em(24);
$em-25: em(25);
$em-26: em(26);
$em-27: em(27);
$em-28: em(28);
$em-30: em(30);
$em-31: em(31);
$em-32: em(32);
$em-34: em(34);
$em-35: em(35);
$em-36: em(36);
$em-38: em(38);
$em-39: em(39);
$em-40: em(40);
$em-43: em(43);
$em-44: em(44);
$em-48: em(48);
$em-50: em(50);
$em-51: em(51);
$em-52: em(52);
$em-54: em(54);
$em-55: em(55);
$em-56: em(56);
$em-60: em(60);
$em-64: em(64);
$em-61: em(61);
$em-63: em(63);
$em-65: em(65);
$em-69: em(69);
$em-70: em(70);
$em-72: em(72);
$em-75: em(75);
$em-74: em(74);
$em-80: em(80);
$em-88: em(88);
$em-90: em(90);
$em-100: em(100);
$em-120: em(120);
$em-140: em(140);
$em-150: em(150);
$em-200: em(200);
$em-250: em(250);


// Función que convierte pixeles en rem
@function rem($pixels, $context: $baseFontSize) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return math.div($pixels, $context) * 1rem;
}

//base font size
$baseFontSize: 16px;

// px to rem
$rem-5: rem(5);
$rem-8: rem(8);
$rem-10: rem(10);
$rem-11: rem(11);
$rem-12: rem(12);
$rem-13: rem(13);
$rem-14: rem(14);
$rem-15: rem(15);
$rem-16: rem(16);
$rem-17: rem(17);
$rem-18: rem(18);
$rem-20: rem(20);
$rem-21: rem(21);
$rem-22: rem(22);
$rem-24: rem(24);
$rem-25: rem(25);
$rem-26: rem(26);
$rem-27: rem(27);
$rem-28: rem(28);
$rem-30: rem(30);
$rem-31: rem(31);
$rem-32: rem(32);
$rem-34: rem(34);
$rem-35: rem(35);
$rem-36: rem(36);
$rem-38: rem(38);
$rem-39: rem(39);
$rem-40: rem(40);
$rem-43: rem(43);
$rem-44: rem(44);
$rem-48: rem(48);
$rem-50: rem(50);
$rem-51: rem(51);
$rem-52: rem(52);
$rem-54: rem(54);
$rem-55: rem(55);
$rem-56: rem(56);
$rem-60: rem(60);
$rem-64: rem(64);
$rem-61: rem(61);
$rem-63: rem(63);
$rem-65: rem(65);
$rem-69: rem(69);
$rem-70: rem(70);
$rem-74: rem(74);
$rem-75: rem(75);
$rem-80: rem(80);
$rem-88: rem(88);
$rem-90: rem(90);
$rem-100: rem(100);
$rem-110: rem(110);
$rem-120: rem(120);
$rem-140: rem(140);
$rem-150: rem(150);
$rem-200: rem(200);
$rem-250: rem(250);
$rem-300: rem(300);
$rem-340: rem(340);

:export {
  primaryColor: $primaryColor;
  secondaryColor: $secondaryColor;
}