@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400&family=PT+Sans+Narrow:wght@400;700&display=swap'
);
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

html {
  overflow-x: hidden;
}

html,
body {
  margin: 0px;
  padding: 0px;
  background-color: #FFFFFF;

  @media (max-width: 992px) {
    background-color: #f5f5f5;

  }

  * {
    font-family: $CoreSansA45Regular;
    font-size: 100%;
  }

  p {
    margin: 0;
    color: $gray;
  }

  h2,
  h3,
  h4 {
    margin: 0;
    font-family: $CoreSansA65Bold;
  }

}

.full-height {
  height: 100%;
}

.position-relative {
  position: relative;
}

.yellow-line {
  position: relative;
  margin-bottom: 20px;

  &::before {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0%;
    // transform: translate(-50%, -50%);
    width: 172px;
    height: 2px;
    background: #F5BD47;

    @media (max-width: 992px) {
      left: 50%;
      transform: translate(-50%, -50%);

    }
  }
}

.yellow-line-login{
  position: relative;
  margin-bottom: 8px;
  &::before {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 300px;
    height: 2px;
    background: #F5BD47;
  }
}

.br-responsive {
  &-sm {
    @media (min-width: 576px) {
      display: none;
    }
  }

  &-md {
    @media (min-width: 768px) {
      display: none;
    }
  }

  &-lg {
    @media (min-width: 992px) {
      display: none;
    }
  }
}

.mc-tag-card {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 2;
  background-color: $primaryColor;
  color: #fff;
  font-size: 10px;
  padding: 2px 5px;
  padding-bottom: 3px;

  &.position-left {
    right: auto;
    left: 10px;
  }
}

.text {
  &-center {
    text-align: center;
  }

  &-start {
    text-align: start;
  }

  &-end {
    text-align: end;
  }

  &-justify {
    text-align: justify;
  }

  &-shadow {
    text-shadow: 0px var(--Sizes-xs, 4px) var(--Sizes-s, 8px) rgba(32, 32, 32, 0.30);
  }
}

.border {
  &-5 {
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
  }

  &-10 {
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }

  &-15 {
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
  }

  &-20 {
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
  }

  &-24 {
    border-radius: 24px;
    -webkit-border-radius: 24px;
    -moz-border-radius: 24px;
    -ms-border-radius: 24px;
    -o-border-radius: 24px;
  }

  &-25 {
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
  }

  &-50 {
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
  }

  &-bottom {
    &-64 {
      border-bottom-left-radius: 64px;
      border-bottom-right-radius: 64px;
      background: #FFFFFF !important;

      // border-bottom: solid 1.5px grey;
      @media (max-width: 992px) {
        border-bottom-left-radius: 24px;
        border-bottom-right-radius: 24px;

      }
      &-md{
        @media (min-width: 992px) {
         background: none !important;
        }
      }
    }
  }
}

.line {
  &-normal {
    line-height: normal;
  }

  &-12 {
    line-height: $rem-12;
  }

  &-13 {
    line-height: $rem-13;
  }

  &-18 {
    line-height: $rem-18;
  }

  &-22 {
    line-height: $rem-24;
  }

  &-24 {
    line-height: $rem-24;
  }

  &-26 {
    line-height: $rem-26;
  }
  &-28 {
    line-height: $rem-28;
  }

  &-38 {
    line-height: $rem-38;
  }

  &-48 {
    line-height: $rem-48;
  }

  &-50 {
    line-height: $rem-50;
  }

  &-52 {
    line-height: $rem-52;
  }

  &-55 {
    line-height: $rem-55;
  }

  &-60 {
    line-height: $rem-60;
  }

  &-64 {
    line-height: $rem-64;
  }

  &-69 {
    line-height: $rem-69;
  }
}

.decoration {
  &-underline {
    text-decoration: underline;

  }

  &-hover {
    &:hover {
      text-decoration: underline;
    }
  }
}

.flex {
  display: flex;

  &-direction-column {
    flex-direction: column;
  }

  &-justify-start {
    justify-content: flex-start;
  }

  &-justify-end {
    justify-content: flex-end;
  }

  &-justify-center {
    justify-content: center;
  }

  &-justify-space-around {
    justify-content: space-around;
  }

  &-justify-space-between {
    justify-content: space-between;
  }

  &-justify-space-evenly {
    justify-content: space-evenly;
  }

  &-align-start {
    align-items: flex-start;
  }

  &-align-end {
    align-items: flex-end;
  }

  &-align-center {
    align-items: center;
  }
}

.color {
  &-white {
    // color: $white;
  }

  &-green {
    color: $green !important;
  }

  &-green2 {
    color: $green2 !important;
  }

  &-gray {
    color: $gray !important;
  }
  &-gray2 {
    color: $gray2 !important;
  }
  &-gray3 {
    color: $gray3 !important;
  }

  &-blue2 {
    // color: $blue-secondary2;
  }

  &-blue-primary-1 {
    // color: $blue-primary1;
  }

  &-blue-light {
    // color: $blue-light;
  }

  &-blue-light2 {
    // color: $blue-light2;
  }

  &-red {
    // color: $red;
  }
}

.font {
  &-regular {
    font-family: $CoreSansA45Regular;
  }

  &-light {
    // font-family: $DINProLight;
  }

  &-black {
    font-family: $CoreSansA75ExtraBold;
  }

  &-bold {
    font-family: $CoreSansA65Bold;
  }

  &-medium {
    // font-family: $DINProMedium;
  }

  &-italic {
    // font-family: $DINProRegular;
    font-style: italic;
  }
}

.size {
  &-10 {
    font-size: $em-10;
  }

  &-11 {
    font-size: $em-11;
  }

  &-12 {
    font-size: $em-12 !important;
  }
  &-13 {
    font-size: $em-13 !important;
  }

  &-14 {
    font-size: $em-14;
  }

  &-16 {
    font-size: $em-16;
  }

  &-18 {
    font-size: $em-18 !important;
  }

  &-20 {
    font-size: $em-20;
  }

  &-22 {
    font-size: $em-22;

    @media (max-width: 375px) {
      &-mb-18 {
        font-size: $em-18;
      }
    }
  }

  &-24 {
    font-size: $em-24 !important;
    &-md-20{
      @media (max-width: 768px) {
        font-size: $em-20 !important;
       
      }

    }
  }

  &-26 {
    font-size: $em-26;
  }

  &-28 {
    font-size: $em-28;
  }

  &-30 {
    font-size: $em-30;
  }

  &-32 {
    font-size: $em-32;

    &-mb-24 {
      @media (max-width: 768px) {
        font-size: $em-24;
      }
    }

    &-mb-20 {
      @media (max-width: 576px) {
        font-size: $em-20;
      }
    }
  }

  &-31 {
    font-size: $em-31;
  }

  &-38 {
    font-size: $em-38;
    
    @media (max-width: 768px) {
      font-size: $em-30;
     
    }
  }

  &-40 {
    font-size: $em-40;
  }

  &-48 {
    font-size: $em-48;

    &-mb-32 {
      @media (max-width: 576px) {
        font-size: $em-32;
      }
    }
  }

  &-50 {
    font-size: $em-50;
  }

  &-52 {
    font-size: $em-52;
  }

  &-54 {
    font-size: $em-54;
  }

  &-56 {
    font-size: $em-56;

    @media (max-width: 768px) {
      font-size: $em-40;

    }
  }

  &-60 {
    font-size: $em-60;
  }

  &-63 {
    font-size: $em-63;
  }

  &-64 {
    font-size: $em-64;

    @media (max-width: 768px) {
      font-size: $em-48;

    }
  }

  &-65 {
    font-size: $em-65;
  }

  &-69 {
    font-size: $em-69;
  }

  &-72 {
    font-size: $em-72;

    @media (max-width: 768px) {
      font-size: $em-48;

    }
  }

  &-80 {
    font-size: $em-80;
  }
}

$spacings: (
  0: 0,
  1: 0.25rem,
  2: 0.5rem,
  3: 1rem,
  4: 1.5rem,
  5: 3rem,
  6: 3.5rem,
  7: 4rem,
  8: 4.5rem,
);

@mixin set-margin($direction, $size) {
  @if map-has-key($spacings, $size) {
    margin-#{$direction}: map-get($spacings, $size);
  }

  @else {
    @warn "Size #{$size} is not defined in $spacings map.";
  }
}

@mixin set-padding($direction, $size) {
  @if map-has-key($spacings, $size) {
    padding-#{$direction}: map-get($spacings, $size);
  }

  @else {
    @warn "Size #{$size} is not defined in $spacings map.";
  }
}

// Generar clases de margen
@each $size, $value in $spacings {
  .mt-#{$size} {
    @include set-margin(top, $size);
  }

  .mb-#{$size} {
    @include set-margin(bottom, $size);
  }

  .ml-#{$size} {
    @include set-margin(left, $size);
  }

  .mr-#{$size} {
    @include set-margin(right, $size);
  }

  .mx-#{$size} {
    @include set-margin(left, $size);
    @include set-margin(right, $size);
  }

  .my-#{$size} {
    @include set-margin(top, $size);
    @include set-margin(bottom, $size);
  }
}

// Generar clases de padding
@each $size, $value in $spacings {
  .pt-#{$size} {
    @include set-padding(top, $size);
  }

  .pb-#{$size} {
    @include set-padding(bottom, $size);
  }

  .pl-#{$size} {
    @include set-padding(left, $size);
  }

  .pr-#{$size} {
    @include set-padding(right, $size);
  }

  .px-#{$size} {
    @include set-padding(left, $size);
    @include set-padding(right, $size);
  }

  .py-#{$size} {
    @include set-padding(top, $size);
    @include set-padding(bottom, $size);
  }
}

.m-0 {
  margin: 0 !important;
}

.mx-auto {
  margin: 0 auto !important;
}

.p-0 {
  padding: 0 !important;
}

.fit-content {
  width: fit-content !important;
}
.fill {
  width: -webkit-fill-available !important;
}
.w{
  &-75{
    width: 75% !important;
  }
  &-50{
    width: 50% !important;
  }
}

.page-bg {
  position: relative;
  background-image: url('../../../public/assets/images/fondo.png');
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;

  &::before {
    content: '';
    position: absolute;
    top: 0%;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);

    @media (max-width: 992px) {
      display: none !important;
    }
  }

  @media (max-width: 992px) {
    background: none;

  }

  &-blend {
    background-blend-mode: color-burn !important;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.bg {
  &-gay {
    background: #F5F5F5 !important;
  }
}

.d {
  &-none {
    display: none;
  }

  &-block {
    display: block;
  }

  &-md-none {
    @media (min-width: 768px) {
      display: none;
    }
  }

  &-lg-none {
    @media (min-width: 992px) {
      display: none;
    }
  }

  &-md-block {
    @media (min-width: 768px) {
      display: block;
    }
  }

  &-lg-block {
    @media (min-width: 992px) {
      display: block;
    }
  }
}

.circleBond {
  padding: 5px !important;
  width: 104px;
  height: 104px;
  border: solid 3px $green;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  margin: 0 auto;

  img {
    width: 100%;
  }
}

.btn {
  display: inline-block;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  outline: none;
  border: none;
  text-decoration: none !important;
  transition: all .3s linear;
  -webkit-transition: all .3s linear;
  -moz-transition: all .3s linear;
  -ms-transition: all .3s linear;
  -o-transition: all .3s linear;
  text-align: center;

  &-primary {
    background-color: $green;
    color: #FFFFFF !important;
    width: fit-content;
    padding: 10px 20px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;

    &:hover {
      background-color: $green2;

    }
  }
}