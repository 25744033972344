.ArrowDto {
    display: block;
    width: 273px;
    height: 95px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    left: -10px;
    padding-left: 30px;
    box-sizing: border-box;
    p {
        font-size: 22px;
        font-family: 'CoreSansA75ExtraBold';
        margin-bottom: -5px;
    }
    h4 {
        font-size: 23px;
        font-weight: bold;
        font-family: 'CoreSansA75ExtraBold';
    }
}