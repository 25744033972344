.mc-cardbox-container {
  position: relative;
  padding: 15px;
  box-sizing: border-box;
  margin: 10px 10px 10px 15px;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
  border-radius: var(--Sizes-m, 16px);
  background: var(--Colores-Segmento-Personas-Heroes-Gris-Gris-5, #F7F8F9);
  -webkit-border-radius: var(--Sizes-m, 16px);
  -moz-border-radius: var(--Sizes-m, 16px);
  -ms-border-radius: var(--Sizes-m, 16px);
  -o-border-radius: var(--Sizes-m, 16px);

  &:hover {
    border: 1px solid var(--Colores-Segmento-Equipo-Popular-Naranja-Naranja-5, #FFA300);
    background: var(--Colores-Segmento-Equipo-Popular-Naranja-Naranja-7, #F5BD47);
    box-shadow: 0px 0px 20px 0px #F5BD47;
  }

  .card-login_type_2 {
    .rowCard {
      min-height: 88px;
      display: flex;
      justify-content: start;
      align-items: center;
      gap: 20px;

      .imgStep {
        width: 80px;
        height: 50px;
        position: relative;

        img {
          position: absolute;
          top: -20px;
          left: -40px;
        }
      }
    }
  }
}