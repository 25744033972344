.mc-goal-counter {
	position: relative;
	background-color: #84BD00;
	box-sizing: border-box;
	padding: 10px;
	text-align: center;
	height: 38px;
	border-radius: 8px;
	margin-top: 2px;
	margin-bottom: 6px;
	max-width: 210px;
	@media (max-width: 768px) {
		padding: 10px 0px;
		div {
			font-size: 11px;
		}
	}

	div {
		font-size: 12px;
		font-style: normal;
		font-weight: bold;
		line-height: 16px;
		color: #3F3F3F;
	}

	strong {
		color: #fff;
		background-color: #009844;
		width: 20px;
		height: 20px;
		display: inline-block;
		vertical-align: middle;
		border-radius: 100px;
		line-height: 20px;
		margin-top: -3px;
		text-align: center;
		box-shadow: 0px 2px 5px 1px rgba(0,0,0,0.27);
		-webkit-box-shadow: 0px 2px 5px 1px rgba(0,0,0,0.27);
		-moz-box-shadow: 0px 2px 5px 1px rgba(0,0,0,0.27);
	}

	@media (max-width: 450px) {
		padding: 12px 0px;
		div {
			font-size: 10px;
			white-space: nowrap;
		}
	}

}

.contentProgress {
	.mc-app-columns {
		@media (max-width: 400px) {
			margin-top: 20px;
		}
		.mc-app-columns__item {
			&:first-of-type {
				@media (max-width: 400px) {
					.mc-app-rely {
						width: 74%;
						transform: scale(0.9);
					}
				}
			}
			&:last-of-type {
				@media (max-width: 400px) {
					.mc-app-rely {
						margin-right: -12px;
						margin-left: -22px;
					}
				}
				@media (max-width: 420px) {
					.mc-app-rely {
						.mc-app-centry {
							& > p {
								font-size: 14px;
							}
						}
					}
				}
			}
		}

		&.mc-app-columns__col-desk-1 {
			@media (max-width: 420px) {
				.mc-app-rely {
					width: 100% !important;
					transform: scale(1) !important;
					margin-top: 20px;
				}
			}
		}
	}
}

.circleProgreessContainer {
	svg {
		text {
			@media (max-width: 420px) {
				font-size: 22px;
			}
		}
	}
}