.Experience{
    padding: 1.25rem;
    .rowExperience{
        display: flex;
        justify-content: center;
        align-items: center; 
        @media (max-width: 992px) {
         flex-direction: column;
        }    
        .imgExperience{
            img{
                width: 100%;
            }
        }
        .contentExperience{
            p.title-experience{                              
                font-size: $em-32;
                @media (max-width: 768px) {
                    font-size: $em-30;                 
                }            
                @media (max-width: 576px) {
                    font-size: $em-24;                 
                }            
                span{                            
                    color: $green;
                    font-family: $CoreSansA75ExtraBold;                                      
                }
            }
            @media (max-width: 992px) {
             text-align: center;

             .size-32.pb-2 {
                padding: 0 13px;
             }
            }    
        }
    }
}