.mc-page {
  position: relative;
  padding: 0 0 10px;

  &.dashboard {
    background-color: rgb(240, 240, 240);
    padding-top: 25px;

    .mc-app-container {
      width: 1110px;
      padding: 0 15px;
    }
  }

  &.terms,
  &.faqs {
    h1 {
      @media only screen and (max-width: 992px) {
        text-align: center;
      }
    }
  }

  &.terms {

    .tyc {
      border-bottom: 1px solid var(--Colores-Segmento-Personas-Heroes-Gris-Gris-4, #E5E4E6);
      background: #E9FFE7;
      padding: 10px;

      article {
        text-align: justify;
        margin: .9375rem 0;

        h4 {
          color: $green2 !important;         
          font-weight: bold; 
          font-size: 1.5rem;
          margin-bottom: .625rem;
          u{
            padding-left: 2.5rem;
          }
        }

        p {
          font-size: 1.5rem;
          color: $gray;
          margin-bottom: 10px;

          a {            
            text-decoration: underline;
            color: $gray;
            line-break: anywhere;

            &:hover {
              font-weight: bold;
            }
          }
        }

        ul {
          list-style: none;
        }
      }
    }

    @media (max-width: 992px) {
      section.border-bottom-64 {
        padding-top: 30px;
      }

      h2.size-48 {
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 40px;
        margin-bottom: 16px;
        padding-top: 30px;
        padding-bottom: 30px;
      }
      .tyc {
        p {
          font-size: 13px !important;
          font-style: normal !important;
          font-weight: 400 !important;
          line-height: 14px !important;
        }
        h4 {
          font-size: 17px !important;
          font-style: normal !important;
          font-weight: 400 !important;
          line-height: 20px !important;
        }
      }
    }
  }

  &.redemptions {
    .mc-preloader-container {
      img {
        width: 30px;
      }
    }
    @media (max-width: 992px) {
      section.border-bottom-64 {
        padding-top: 30px;
      }

      h2.size-48 {
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 40px;
        margin-bottom: 16px;
      }
      h2.size-32 {
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
      }
      h3.size-32 {
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
      }
      p.size-24.color-gray {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
      .mc-awards-container {
        margin-top: 20px;
      }
    }
  }

  .mc-static-content {
    margin: 30px 0;

    @media only screen and (min-width: 992px) {
      margin: 50px 0;
    }

  }

  &.alliances {
    @media (max-width: 992px) {
      section.pt-7.px-2 {
        padding-top: 0px;
      }

      .mc-app-columns__item {
        .mc-app-centry {
          .py-4 {}
        }

        .mc-app-container {
          padding: 0px !important;
        }
      }
    }

    .alliances-actions {
      text-align: left;
      margin-top: 25px;

      .mc-button-container {
        display: inline-block;
      }
    }

    @media (min-width: 992px) {
      article.border-bottom-64 {
        padding-top: 30px;
      }

      h2.size-38 {
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: 34px;
        margin-bottom: 0px;
      }
      h2.size-32 {
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
      }
      h3.size-32 {
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
      }
      p.size-24.color-gray {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
      .alliances_container {
        .py-4 {
          min-width: 325px;
        }
        .custom-cta {
          display: block;
          width: 100% !important;
          max-width: 280px !important;

        }
      }
    }

    @media (max-width: 992px) {
      .alliances_container {
        .custom-cta {
          max-width: 100% !important;
          width: 100% !important;
        }
        
      }
    }
    .alliances_container {
      .mc-app-centry {
        .mc-app-container {
          padding: 0px !important;
          margin-right: 0px;
          margin-left: 10px;
        }
      }
      
    }
  }

  &.mechanic {
    .color-green.text-center.py-4 {
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;
    }

    @media (max-width: 992px) {
      article.border-bottom-64 {
        padding-top: 30px;
      }

      h2.size-48 {
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: 34px;
        margin-bottom: 0px;
      }
      h2.size-32 {
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
      }
      h3.size-32 {
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
      }
      p.size-24.color-gray {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
      .card-mechanic {
        .imgMechanic {
          img {
            display: block;
            width: 100%;
            margin: 0px !important;
          }
        }
        .titleMechanic {
          background-color: #fff;
          padding-top: 10px;
        }
        .content_card_mechanic {
          background-color: #fff;
          padding-left: 10px;
          padding-right: 10px;
          padding-bottom: 20px;
          -webkit-border-bottom-right-radius: 20px;
          -webkit-border-bottom-left-radius: 20px;
          -moz-border-radius-bottomright: 20px;
          -moz-border-radius-bottomleft: 20px;
          border-bottom-right-radius: 20px;
          border-bottom-left-radius: 20px;
        }
      }
    }
  }

  &.progress {
    @media (max-width: 992px) {
      section.border-bottom-64 {
        padding-top: 30px;
      }

      h2.size-48 {
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 40px;
      }
      h2.size-32 {
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
      }
      h3.size-32 {
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
      }
      p.size-24.color-gray {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
      .slick-slider {
        margin: 30px 0px;
        margin-bottom: 40px;
        .slick-dots {
          bottom: -32px;
          li {
            background-color: transparent;
            border-radius: 100px;
            border: 2px solid #016836;
            width: 16px;
            height: 16px;
  
            button {
                width: 100%;
                height: 100%;
                display: block;
                padding: 0px;
    
                &:before {
                    display: none;
                }
            }
  
            &.slick-active {
              background-color: #016836;
              width: 48px;
            }
          }
        }
      }
      
    }
  }

  &.awards  {
    @media (max-width: 992px) {
      article.border-bottom-64 {
        padding-top: 30px;
      }

      h2.size-48 {
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: 34px;
        margin-bottom: 0px;
      }
      h2.size-32 {
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
      }
      h3.size-32 {
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
      }
      p.size-24.color-gray {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }
}

.App.loggedIn {
  @media (max-width: 992px) {
    .mc-footer-container__text {
      padding-bottom: 120px;
    }
    .mc-footer-vigilated {
      bottom: 360px;
    }
  }
}

.border-radius {
  border-radius: 20px;
  overflow: hidden;
}