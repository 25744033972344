.loginHome {
	background-color: #FFFFFF;
	padding-top: 3.125rem;
	@media (max-width: 992px) {
		background: #F5F5F5;
		padding-top: 1.25rem;
		
	}
	.customRow {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-right: -0.9375rem;
		margin-left: -0.9375rem;
		@media (max-width: 992px) {
		 flex-direction: column;
		 align-items: center;
		}
	}

	.colLeft {
		width: 60%;
		@media (max-width: 1200px) {
			width: 50%;
		 
		}
		@media (max-width: 992px) {
		 width: 100%;
		}
		.imageCardsLogin{
			width: 100%;
			max-width: 40%;
			@media (max-width: 992px) {
				max-width: 85%;
			 
			}
		}

	}

	.colright {
		width: 40%;
		@media (max-width: 1200px) {
			width: 50%;
		 
		}
		@media (max-width: 992px) {
		 width: 100%;
		}
		.login-banner{
			img{
				width: 90%;
				@media (max-width: 992px) {
				 display: none;
				}
			}
		}
	}

	.bgCol {
		position: relative;
		border-radius: var(--Sizes-l, 1.5rem);
		-webkit-border-radius: var(--Sizes-l, 1.5rem);
		-moz-border-radius: var(--Sizes-l, 1.5rem);
		-ms-border-radius: var(--Sizes-l, 1.5rem);
		-o-border-radius: var(--Sizes-l, 1.5rem);
		background: #EEE;		
		padding: 32px 32px 30px 1.875rem;
		height: 550px;
		@media (max-width: 992px) {
			height: auto;
		 
		}

		&::before {
			content: '';
			position: absolute;
			top: 0;
			right: 0;			
			width: 78px;
			height: 100%;
			background: linear-gradient(90deg, rgba(217, 217, 217, 0.00) 0%, rgba(157, 157, 157, 0.50) 100%);
			mix-blend-mode: multiply;
			border-radius: 0px var(--Sizes-l, 24px) var(--Sizes-l, 24px) 0px;
			-webkit-border-radius: 0px var(--Sizes-l, 24px) var(--Sizes-l, 24px) 0px;
			-moz-border-radius: 0px var(--Sizes-l, 24px) var(--Sizes-l, 24px) 0px;
			-ms-border-radius: 0px var(--Sizes-l, 24px) var(--Sizes-l, 24px) 0px;
			-o-border-radius: 0px var(--Sizes-l, 24px) var(--Sizes-l, 24px) 0px;
		}
		@media (max-width: 992px) {
		 background-color: transparent;
		 padding: .625rem;
		 &::before{
			display: none;
		 }
		 .login-description{
			text-align: center;
		 }
		}
	}

	.imageCardsLogin {
		@media (min-width: 992px) {
			max-width: 229px;
		}
	}

}