@font-face {
    font-family: 'CoreSansA65Bold';
    src: url('../../fonts/CoreSansA65Bold_2.ttf') format('truetype');
    // font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'CoreSansA45Regular';
    src: url('../../fonts/CoreSansA45Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'CoreSansA75ExtraBold';
    src: url('../../fonts/CoreSansA75ExtraBold.ttf') format('truetype');
    font-style: normal;
    font-display: swap;
  }