.mainHeaderPopular {
    background-color: $green;
    min-height: 62px;

    .contentHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 62px;

        @media (max-width: 992px) {
            justify-content: center;

        }

        .logo {}

        .navigation {
            .btnProgressFloat-header {
                display: none;
            }

            nav {
                .ulHeader {
                    list-style: none;
                    padding: 0;
                    display: flex;
                    gap: 10px;

                    li.item-nav {
                        margin: 0 .3125rem;
                        display: flex;
                        align-items: center;

                        .link-nav {
                            color: #FFFFFF;
                            text-decoration: none;
                            position: relative;
                            &::before {
                                content: '';
                                position: absolute;
                                bottom: -8px;
                                left: 0px;
                                background-color: #FFFFFF;
                                width: 100%;
                                height: 2px;
                                opacity: .5;
                                @media (max-width: 992px) {
                                 display: none;
                                }
                            }
                            

                            &.active {
                                position: relative;

                               @media (min-width: 992px) {
                                &::before {
                                    opacity: 1;
                                }
                               }
                            }

                            span {
                                display: inline-block;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                gap: 8px;

                                .icon-nav {
                                    display: none;
                                }
                            }

                            position: relative;

                            // &:after {
                            //     content: '';
                            //     position: absolute;
                            //     left: 0px;
                            //     right: 0px;
                            //     bottom: -5px;
                            //     height: 1px;
                            //     background-color: #fff;
                            //     opacity: 0.5;
                            // }

                            &.active {
                                
                            }
                        }
                    }
                }
            }

            @media (max-width: 992px) {
                width: -webkit-fill-available;
                position: fixed;
                bottom: 0;
                z-index: 1000;
                background-color: $green;
                background: var(--Colores-Segmento-Personas-Primario-Primario-Personas-1, #009844);
                padding: 1rem;
                border-radius: 40px;
                -webkit-border-radius: 40px;
                -moz-border-radius: 40px;
                -ms-border-radius: 40px;
                -o-border-radius: 40px;

                nav {
                    .ulHeader {
                        justify-content: space-evenly;
                        transition: all .3s linear;
                        -webkit-transition: all .3s linear;
                        -moz-transition: all .3s linear;
                        -ms-transition: all .3s linear;
                        -o-transition: all .3s linear;

                        li.item-nav {
                            .link-nav {
                                display: flex;
                                align-items: center;

                                span {
                                    background: none;
                                    position: relative;

                                    .icon-nav {
                                        display: block;
                                    }
                                }

                                .NavText {
                                    font-size: .875rem;
                                    position: absolute;
                                    z-index: -1;
                                    opacity: 0;
                                    visibility: hidden;
                                    margin-bottom: 5px;
                                }

                                &.active {

                                    span {
                                        padding: 10px;
                                        background-color: $green2;
                                        border-radius: var(--Sizes-l, 24px);
                                        -webkit-border-radius: var(--Sizes-l, 24px);
                                        -moz-border-radius: var(--Sizes-l, 24px);
                                        -ms-border-radius: var(--Sizes-l, 24px);
                                        -o-border-radius: var(--Sizes-l, 24px);

                                        .NavText {
                                            opacity: 1;
                                            visibility: visible;
                                            font-weight: bold;
                                            position: static;
                                            z-index: 0;
                                            transition: position .3s linear;
                                            -webkit-transition: position .3s linear;
                                            -moz-transition: position .3s linear;
                                            -ms-transition: position .3s linear;
                                            -o-transition: position .3s linear;
                                        }
                                    }
                                }

                            }

                            &.logoMC {
                                display: none;
                            }
                        }
                    }
                }

                .btnProgressFloat-header {
                    display: inline-block;
                    width: 50px;
                    height: 50px;
                    position: absolute;
                    top: -20px;
                    left: 50%;
                    border: none;
                    background-color: $green;
                    border-radius: 50%;
                    -webkit-border-radius: 50%;
                    -moz-border-radius: 50%;
                    -ms-border-radius: 50%;
                    -o-border-radius: 50%;
                    transform: translateX(-50%);
                    -webkit-transform: translateX(-50%);
                    -moz-transform: translateX(-50%);
                    -ms-transform: translateX(-50%);
                    -o-transform: translateX(-50%);
                    img{
                        position: relative;
                        top: -10px;
                    }
                }
            }

            @media (max-width: 992px) {
                padding-top: 0 !important;
                padding-bottom: 0 !important;
                ul.ulHeader {
                    margin-top: 8px !important;
                    margin-bottom: 8px !important;
                }
            }
        }
    }
}