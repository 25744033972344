.AwardsBanner {
	.awards_log {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 20px;
		flex-wrap: wrap;

		.logos {
			display: inline-block !important;
			width: 108px;

			img {
				width: 100%;
				border-radius: 100px;
			}
		}
	}

}
.slick-logos{

	.logos {	
		display: inline-block !important;
		width: 100%;		
		img {
			width: 80%;			
		}
	
	}
	&.center {		
		.logos{
			outline: none;
		}
	}

}