.mc-button-container {
  display: block;
  width: 100%;
  text-align: center;

  button {
    padding: 12px 24px;
    display: block;
    width: 100%;
    border: 0px;
    height: 45px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    cursor: pointer;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    .inline-loader{
      width: 30px;
      height: auto;
      display: inline-block;
      vertical-align: middle;
    }
  }

  &__primary {
    button {
      background-color: $green;
      color: #fff;

      &:hover {
        background-color: $green2;
      }
      &.mc-button-container__status--inactive {
        background-color: $primaryColorDisable;
        cursor: no-drop;
        opacity: 0.7;
      }
    }
  }

  &__secondary {
    button {      
      color: $gray;  
      border-radius: var(--Sizes-s, 7.443px);
      background: var(--Colores-Neutros-Neutro-7, #BDBDBD);
      
      &:hover {
        color: #FFFFFF;  
        background-color: $primaryColorLight;
      }
      &.mc-button-container__status--inactive {
        background-color: $secondaryColorDisable;
        cursor: no-drop;
        opacity: 0.7;
      }
    }
  }
}